import { ReactNode } from 'react'
import { Box, Link } from '@mui/material'

import { FacebookIcon } from './socialIcons/Facebook'
import { InstagramIcon } from './socialIcons/Instagram'
import { LinkedinIcon } from './socialIcons/Linkedin'
import { XTwitterIcon } from './socialIcons/XTwitter'
import { YoutubeIcon } from './socialIcons/Youtube'

const IconWrapper = ({
  children,
  fill,
  borderColor,
  borderRadius,
  bgHoverColor,
}: {
  children: ReactNode
  fill: string
  bgHoverColor: string
  borderColor?: string
  borderRadius?: string
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    border={`${borderRadius ? '2px' : '1px'} solid ${borderColor || fill}`}
    width={{ xs: '40px', sm: '32px' }}
    height={{ xs: '40px', sm: '32px' }}
    p={(theme) => ({ xs: theme.spacing(0.5), sm: theme.spacing(0.25) })}
    borderRadius={(theme) => borderRadius || theme.spacing(0.5)}
    sx={{
      ':hover': {
        bgcolor: bgHoverColor,
      },
    }}
  >
    {children}
  </Box>
)

export interface SocialLinks {
  instagram: string
  facebook: string
  xtwitter: string
  youtube: string
  linkedin: string
}

interface Props {
  links: SocialLinks
  bgHoverColor: string
  fill: string
  borderColor?: string
  borderRadius?: string
}

const SocialIcons = (props: Props) => {
  const { links, borderColor, fill, borderRadius, bgHoverColor } = props

  const socialIconsData = [
    {
      label: 'Youtube',
      href: links.youtube,
      icon: <YoutubeIcon fill={fill} />,
    },
    {
      label: 'LinkedIn',
      href: links.linkedin,
      icon: <LinkedinIcon fill={fill} />,
    },
    {
      label: 'Instagram',
      href: links.instagram,
      icon: <InstagramIcon fill={fill} />,
    },
    {
      label: 'Facebook',
      href: links.facebook,
      icon: <FacebookIcon fill={fill} />,
    },
    { label: 'X', href: links.xtwitter, icon: <XTwitterIcon fill={fill} /> },
  ]

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap={(theme) => theme.spacing(2)}
    >
      {socialIconsData.map(({ label, href, icon }) => (
        <Link key={label} href={href} target="_blank" aria-label={label}>
          <IconWrapper
            borderColor={borderColor}
            fill={fill}
            borderRadius={borderRadius}
            bgHoverColor={bgHoverColor}
          >
            {icon}
          </IconWrapper>
        </Link>
      ))}
    </Box>
  )
}
export { SocialIcons }
