import { Box, Link } from '@mui/material'

export interface FooterLinks {
  agb: string
  impressum: string
  datenschutz: string
}

interface Props {
  oneTrustHandler: () => void
  links: FooterLinks
}

const StyledLink = ({
  href,
  children,
  onClick,
}: {
  href?: string
  children: string
  onClick?: () => void
}) => (
  <Link
    href={href}
    target="_blank"
    color="grey.900"
    pb={(theme) => ({ xs: theme.spacing(2), sm: theme.spacing(0.5) })}
    style={{ textDecoration: 'none', cursor: 'pointer' }}
    flexBasis={{ xs: '50%', sm: 'inherit' }}
    fontSize={(theme) => theme.spacing(1.75)}
    onClick={onClick}
    sx={{
      ':hover': {
        color: (theme) => theme.palette.grey[500],
      },
    }}
  >
    {children}
  </Link>
)

const Links = ({ oneTrustHandler, links }: Props) => (
  <Box
    display="flex"
    width="100%"
    flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
    justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
    gap={(theme) => ({ sm: theme.spacing(2) })}
  >
    <StyledLink href={links.agb}>AGB</StyledLink>
    <StyledLink href={links.impressum}>Impressum</StyledLink>
    <StyledLink href={links.datenschutz}>Datenschutz</StyledLink>
    <StyledLink onClick={oneTrustHandler}>Cookies</StyledLink>
  </Box>
)

export { Links }
