/**
 * This ugly stuff is here due to problems between mui 4.x and emotion
 * and should be gone when we switch to mui 5.x thoughout the projects
 */
export const SPACE = '4px'
export const SPACE2 = `8px`
export const SPACE3 = `12px`
export const SPACE4 = '16px'
export const SPACE5 = '20px'
export const SPACE6 = '24px'
export const SPACE8 = '32px'
export const SPACE9 = '36px'
export const SPACE10 = '40px'
export const SPACE12 = '48px'
export const SPACE13 = '52px'
export const SPACE14 = '56px'
export const SPACE16 = '64px'
export const SPACE18 = '72px'
export const SPACE20 = '80px'
export const spacing = (space = 1) => `${8 * space}px`
