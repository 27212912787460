import { SocialLinks } from './SocialIcons'

// SOCIAL LINKS
export const smavaSocialIconsLinks: SocialLinks = {
  instagram: 'https://www.instagram.com/smava_gmbh/',
  facebook: 'https://www.facebook.com/people/smava/100064534726416/',
  xtwitter: 'https://twitter.com/smava',
  youtube: 'https://www.youtube.com/@smava.',
  linkedin: 'https://de.linkedin.com/company/smava',
}

export const finanzcheckSocialIconsLinks: SocialLinks = {
  instagram: 'https://www.instagram.com/finanzcheck.de',
  facebook: 'https://www.facebook.com/p/FINANZCHECKde-100039268431885/',
  xtwitter: 'https://twitter.com/finanzcheck_de',
  youtube: 'https://www.youtube.com/channel/UCXufMPgryYGPINluehn_91Q',
  linkedin: 'https://www.linkedin.com/company/finanzcheck-de/',
}

// SOCIAL ICONS STYLES
// when theme is available for FFG and Smava, please use theme.palette values instead of hardcoded colors
export const smavaSocialIconColor = '#39A949'
export const smavaSocialIconBgHoverColor = '#E4F6E6'

export const finanzcheckSocialIconColor = '#165297'
export const finanzcheckSocialIconBorderColor = '#D0DCEA'
export const finanzcheckSocialIconBgHoverColor = '#F3F6FA'
export const finanzcheckSocialIconBorderRadius = '50%'

// ADDRESS
export const getAddress = (origin: 'smava' | 'finanzcheck') => {
  const currentYear = new Date().getFullYear()

  if (origin === 'smava') {
    return `© ${currentYear} smava.de | Palisadenstraße 90 | 10243 Berlin`
  }

  return `© ${currentYear} FINANZCHECK.de · Admiralitätstraße 60 - 20459 Hamburg`
}

// FOOTER DATA
// we have two main footers: finanzcheck & smava, that are used with the same pack of props everytime
// however, feel free to edit and extend the footer component and pass your own props if needed
export const getFooterData = (origin: 'smava' | 'finanzcheck') =>
  origin === 'smava'
    ? {
        socialIconsLinks: smavaSocialIconsLinks,
        socialIconColor: smavaSocialIconColor,
        socialIconBgHoverColor: smavaSocialIconBgHoverColor,
        address: getAddress(origin),
      }
    : {
        socialIconsLinks: finanzcheckSocialIconsLinks,
        socialIconColor: finanzcheckSocialIconColor,
        socialIconBorderColor: finanzcheckSocialIconBorderColor,
        socialIconBorderRadius: finanzcheckSocialIconBorderRadius,
        socialIconBgHoverColor: finanzcheckSocialIconBgHoverColor,
        address: getAddress(origin),
      }
