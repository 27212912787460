export const getLogoLink = (): string => {
  const href = location.search;
  const queryObject: { backUrl?: string } = location.search
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

  if (
    queryObject &&
    queryObject.backUrl &&
    queryObject.backUrl.indexOf("lp") > -1
  ) {
    return "";
  }

  return `/${href}`;
};
