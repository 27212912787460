import { memo, useState } from 'react'
import styled from '@emotion/styled'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined'
import FeedbackIcon from '@mui/icons-material/Feedback'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Link } from '@mui/material'
import { spacing } from '../../theme-workaround/layout'

export enum BannerType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

// @TODO colors are not available in theme, please define them
const getBackgroundColor = (type: BannerType) => {
  switch (type) {
    case BannerType.ERROR:
      return '#FFE8E8'
    case BannerType.WARNING:
      return '#FEF4E6'
    case BannerType.INFO:
      return '#E8EEF5'
    case BannerType.SUCCESS:
      return '#F6FDF5'
    default:
      return '#E8EEF5'
  }
}

// primary color is used for border and icon
const getPrimaryColor = (type: BannerType) => {
  switch (type) {
    case BannerType.ERROR:
      return '#AE2E2E'
    case BannerType.WARNING:
      return '#D88303'
    case BannerType.INFO:
      return '#165297'
    case BannerType.SUCCESS:
      return '#39A949'
    default:
      return '#165297'
  }
}

const getLinkTextColor = (type: BannerType) => {
  switch (type) {
    case BannerType.ERROR:
      return '#6D1C1C'
    case BannerType.WARNING:
      return '#C07402'
    case BannerType.INFO:
      return '#124279'
    case BannerType.SUCCESS:
      return '#277634'
    default:
      return '#124279'
  }
}

interface ContainerProps {
  type: BannerType
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  gap: ${spacing(2)};
  padding: ${spacing(2)};
  border: 1px solid ${(props) => getPrimaryColor(props.type)};
  border-radius: ${spacing(1)};
  background: ${(props) => getBackgroundColor(props.type)};
  position: relative;
`

const Title = styled(Typography)`
  margin-bottom: ${spacing(1)};
  font-weight: 600;
`

interface LinkProps {
  bannerType: BannerType
}

const StyledLink = styled(Link)<LinkProps>`
  color: ${(props) => getLinkTextColor(props.bannerType)};
`

interface LinkTextProps {
  bannerType: BannerType
}

const LinkText = styled(Typography)<LinkTextProps>`
  font-weight: 600;
  text-transform: unset;
  color: ${(props) => getLinkTextColor(props.bannerType)};
`

const BannerLinkContainer = styled.div`
  margin-top: ${spacing(1)};
`

const LinkContent = styled.div`
  display: flex;
  align-items: center;
`

interface BannerIconContainerProps {
  bannerType: BannerType
}

const BannerIconContainer = styled.div<BannerIconContainerProps>`
  display: flex;
  svg {
    color: ${(props) => getPrimaryColor(props.bannerType)};
  }
`

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`

interface BannerIconProps {
  bannerType: BannerType
  isIconVersionOutline?: boolean
}

const BannerIcon = ({ bannerType, isIconVersionOutline }: BannerIconProps) => {
  if (isIconVersionOutline) {
    return (
      <BannerIconContainer bannerType={bannerType}>
        {bannerType === BannerType.ERROR && (
          <WarningAmberOutlined fontSize="small" />
        )}
        {bannerType === BannerType.WARNING && (
          <FeedbackOutlinedIcon fontSize="small" />
        )}
        {bannerType === BannerType.INFO && (
          <InfoOutlinedIcon fontSize="small" />
        )}
        {bannerType === BannerType.SUCCESS && (
          <CheckCircleOutlinedIcon fontSize="small" />
        )}
      </BannerIconContainer>
    )
  }

  return (
    <BannerIconContainer bannerType={bannerType}>
      {bannerType === BannerType.ERROR && <WarningIcon fontSize="small" />}
      {bannerType === BannerType.WARNING && <FeedbackIcon fontSize="small" />}
      {bannerType === BannerType.INFO && <InfoIcon fontSize="small" />}
      {bannerType === BannerType.SUCCESS && (
        <CheckCircleIcon fontSize="small" />
      )}
    </BannerIconContainer>
  )
}

interface Props {
  title?: string
  desc: string | JSX.Element
  linkLabel?: string
  linkHref?: string
  type: BannerType
  isCloseAvailable?: boolean
  isIconHidden?: boolean
  isIconVersionOutline?: boolean
  onClose?: () => void
}

export const TealBanner = memo((props: Props) => {
  const [isClosed, setIsClosed] = useState(false)

  const handleCloseClick = () => setIsClosed(true)

  if (isClosed) {
    return null
  }

  return (
    <Container type={props.type}>
      {!props.isIconHidden && (
        <BannerIcon
          isIconVersionOutline={props.isIconVersionOutline}
          bannerType={props.type}
        />
      )}
      <div>
        {props.title && (
          <Title variant="subtitle2" color={(theme) => theme.palette.grey[900]}>
            {props.title}
          </Title>
        )}
        <Typography variant="body2" color={(theme) => theme.palette.grey[900]}>
          {props.desc}
        </Typography>
        {props.linkLabel && props.linkHref && (
          <BannerLinkContainer>
            <StyledLink
              bannerType={props.type}
              href={props.linkHref}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkContent>
                <LinkText variant="button" bannerType={props.type}>
                  {props.linkLabel}
                </LinkText>
                <KeyboardArrowRightIcon fontSize="small" />
              </LinkContent>
            </StyledLink>
          </BannerLinkContainer>
        )}
      </div>
      {props.isCloseAvailable && (
        <CloseIconStyled
          fontSize="small"
          onClick={props.onClose || handleCloseClick}
        />
      )}
    </Container>
  )
})

TealBanner.displayName = 'TealBanner'
