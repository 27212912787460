interface Props {
  fill?: string
}

const LinkedinIcon = ({ fill = '#39A949' }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.40747 11.9855H10.6129V25.4944H6.40747V11.9855ZM8.5107 5.27246C9.85566 5.27246 10.9451 6.36354 10.9451 7.70547C10.9451 9.0474 9.85532 10.1418 8.5107 10.1418C7.16609 10.1418 6.073 9.04907 6.073 7.70547C6.073 6.36186 7.16239 5.27246 8.5107 5.27246Z"
      fill={fill}
    />
    <path
      d="M13.2476 11.9848H17.2754V13.8325H17.3304C17.8919 12.7693 19.2617 11.6503 21.3065 11.6503C25.5577 11.6503 26.3418 14.4464 26.3418 18.0846V25.4938H22.1457V18.9262C22.1457 17.3582 22.1152 15.3437 19.9633 15.3437C17.8113 15.3437 17.4443 17.05 17.4443 18.8117V25.4938H13.2476V11.9848Z"
      fill={fill}
    />
  </svg>
)

export { LinkedinIcon }
