import { Box, Link } from '@mui/material'
import SmavaLogo from '../Logo/Logo'
import FinanzcheckLogo from '../LogoFinanzcheck/Logo'

interface LogoProps {
  origin: 'smava' | 'finanzcheck'
  logoLink?: string
}

const Logo = ({ origin, logoLink }: LogoProps) => {
  const logoBranch =
    origin === 'smava' ? (
      <Box minWidth={138}>
        <SmavaLogo />
      </Box>
    ) : (
      <Box minWidth={192}>
        <FinanzcheckLogo />
      </Box>
    )

  if (logoLink) {
    return <Link href={logoLink}>{logoBranch}</Link>
  }

  return logoBranch
}

export { Logo }
