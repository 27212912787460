import { Box, Typography } from '@mui/material'

import { SocialIcons } from './SocialIcons'
import { FooterLinks, Links } from './Links'
import { getFooterData } from './consts'
import { Logo } from './Logo'

export interface FooterProps {
  oneTrustHandler: () => void
  origin: 'smava' | 'finanzcheck'
  footerLinks: FooterLinks
  logoLink?: string
}

export default function Footer(props: FooterProps) {
  const { oneTrustHandler, origin, logoLink, footerLinks } = props

  const footerData = getFooterData(origin)

  return (
    <Box
      bgcolor="common.white"
      display="flex"
      justifyContent={{ xs: 'flex-start', md: 'space-between' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      px={(theme) => ({ xs: theme.spacing(2), md: theme.spacing(3) })}
      py={(theme) => ({ xs: theme.spacing(3), md: theme.spacing(2) })}
      sx={{ maxWidth: 'lg' }}
      m="auto"
      gap={(theme) => theme.spacing(3)}
    >
      <Box display="flex" alignItems="center">
        <Logo origin={origin} logoLink={logoLink} />

        <Box
          display={{ xs: 'none', sm: 'block', md: 'none' }}
          ml={(theme) => theme.spacing(4)}
        >
          <SocialIcons
            links={footerData.socialIconsLinks}
            fill={footerData.socialIconColor}
            bgHoverColor={footerData.socialIconBgHoverColor}
            borderColor={footerData.socialIconBorderColor}
            borderRadius={footerData.socialIconBorderRadius}
          />
        </Box>
      </Box>
      <Box display={{ xs: 'block', sm: 'none', md: 'block' }}>
        <SocialIcons
          links={footerData.socialIconsLinks}
          fill={footerData.socialIconColor}
          borderColor={footerData.socialIconBorderColor}
          borderRadius={footerData.socialIconBorderRadius}
          bgHoverColor={footerData.socialIconBgHoverColor}
        />
      </Box>
      <Box>
        <Links oneTrustHandler={oneTrustHandler} links={footerLinks} />
        <Box mt={(theme) => ({ xs: theme.spacing(1), sm: '0' })}>
          <Typography
            color="grey.600"
            fontSize={(theme) => theme.spacing(1.5)}
            textAlign={{ xs: 'left', md: 'right' }}
          >
            {footerData.address}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
