import { createRoot } from "react-dom/client";
import { TealFooterUnified } from "@finanzcheck/teal-components";
import { getLogoLink } from "./lib/logoLink";

const domNode = document.getElementById("footer");

if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <TealFooterUnified
      origin="smava"
      logoLink={getLogoLink()}
      oneTrustHandler={() => window?.OneTrust?.ToggleInfoDisplay()}
      footerLinks={{
        agb: "/agb",
        impressum: "/impressum",
        datenschutz: "/datenschutz",
      }}
    />,
  );
}
