interface Props {
  fill?: string
}

const InstagramIcon = ({ fill = '#39A949' }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9234 8.85455C21.2038 8.85455 20.6201 9.43821 20.6201 10.1579C20.6201 10.8775 21.2038 11.4612 21.9234 11.4612C22.6431 11.4612 23.2267 10.8775 23.2267 10.1579C23.2267 9.43821 22.6431 8.85455 21.9234 8.85455Z"
      fill={fill}
    />
    <path
      d="M16.1295 10.5257C13.1109 10.5257 10.655 12.9815 10.655 16.0002C10.655 19.0189 13.1109 21.4747 16.1295 21.4747C19.1482 21.4747 21.6041 19.0189 21.6041 16.0002C21.6041 12.9815 19.1482 10.5257 16.1295 10.5257ZM16.1295 19.5068C14.1959 19.5068 12.6226 17.9338 12.6226 16.0002C12.6226 14.0666 14.1956 12.4936 16.1295 12.4936C18.0635 12.4936 19.6362 14.0666 19.6362 16.0002C19.6362 17.9338 18.0632 19.5068 16.1295 19.5068Z"
      fill={fill}
    />
    <path
      d="M20.4754 27.1147H11.6004C7.91849 27.1147 4.92334 24.1195 4.92334 20.4376V11.5623C4.92334 7.88036 7.91849 4.88519 11.6004 4.88519H20.4754C24.1573 4.88519 27.1528 7.88036 27.1528 11.5623V20.4376C27.1528 24.1195 24.157 27.1147 20.4754 27.1147ZM11.6004 6.97667C9.07169 6.97667 7.01448 9.03356 7.01448 11.5623V20.4376C7.01448 22.9663 9.07135 25.0235 11.6004 25.0235H20.4754C23.0041 25.0235 25.0614 22.9667 25.0614 20.4376V11.5623C25.0614 9.03356 23.0041 6.97667 20.4754 6.97667H11.6004Z"
      fill={fill}
    />
  </svg>
)

export { InstagramIcon }
