import { memo, Suspense } from 'react'
import Footer, { FooterProps } from './Footer'

export const TealFooterUnified = memo<FooterProps>((args) => (
  <Suspense fallback={null}>
    <Footer {...args} />
  </Suspense>
))

TealFooterUnified.displayName = 'TealFooterUnified'
